<template>
  <div class="page">
    <div class="top-tab">
      <div class="top-tab-cell" v-for="(category, index) in categoryList" :key="`category-${index}`">
        <div :class="['top-tab-cell', tabIndex === index ? 'active' : '']" @click="clickTop(index)">
          <!-- <div v-if="index === 0" class="top-tab-cell-new-new">新</div> -->
          {{category}}
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="page-tuwen" v-for="(item, index) in tuWenData" :key="`tuwen-${index}`">
      <div v-if="item.type === 1 && item.pic !== '' && item.pic43 !== ''" class="page-tuwen-cell" @click="clickTuwenCell(item)">
        <div class="page-tuwen-cell-left">
          <div class="page-tuwen-cell-title">{{item.title}}</div>
          <div class="page-tuwen-cell-other">
            <div class="page-tuwen-cell-share">{{item.shareNum}}分享</div>
            <div class="page-tuwen-cell-time">{{item.time}}分钟前</div>
            <!-- <img class="page-tuwen-cell-up-image" :src="item.upImage"/>
            <div class="page-tuwen-cell-up-title">{{item.upName}}</div> -->
          </div>
        </div>
        <div class="page-tuwen-cell-right">
          <img :src="item.pic"/>
        </div>
      </div>
      <div v-if="item.type === 2 && item.pic !== '' && item.pic43 !== ''" class="page-tuwen-cell-type" @click="clickTuwenCell(item)">
         <div class="page-tuwen-cell-type-title">{{item.title}}</div>
         <div class="page-tuwen-cell-type-imgs">
           <img :src="item.pics1609.length > 2 ?item.pics1609[0] : item.pic" alt="">
           <img :src="item.pics1609.length > 2 ? item.pics1609[1] : item.pic43" alt="">
           <img :src="item.pics1609.length > 2 ? item.pics1609[2] : item.pic1609" alt="">
         </div>
         <div class="page-tuwen-cell-type-other">
           <div class="page-tuwen-cell-type-share">{{item.shareNum}}分享</div>
           <div class="page-tuwen-cell-type-time">{{item.time}}分钟前</div>
         </div>
      </div>
    </div>
    <div class="web-info">
      <div>{{$store.state.urlInfo.homeRecord}}</div>
      <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">{{$store.state.urlInfo.icp}}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
    // 'tab-bars': Tabbars
  },
  data () {
    return {
      tabIndex: 0, // 顶部分类索引
      categoryList: ['综合', '娱乐', '体育'], // 顶部分类
      tuWenData: [],
      path: 'home',
      getGuoNeiData: [], // 国内
      getTiYuData: [], // 体育
      getYuLeData: [], // 娱乐
      getZongHeData: [], // 综合
      getJunShiData: [], // 军事
      getGuoJiData: [] // 国际
    }
  },
  mounted () {
    this.getGuoNeiData = require('../assets/data/guonei.json')
    this.getTiYuData = require('../assets/data/tiyu.json')
    this.getYuLeData = require('../assets/data/yule.json')
    this.getZongHeData = require('../assets/data/zonghe.json')
    this.getJunShiData = require('../assets/data/junshi.json')
    this.getGuoJiData = require('../assets/data/guoji.json')
    this.tuWenData = this.getZongHeData.map(item => {
      item.type = parseInt(Math.random() * 2 + 1, 10)
      item.shareNum = parseInt(Math.random() * 800 + 600, 10)
      item.time = parseInt(Math.random() * 60 + 1, 10)
      return item
    })
    // this._getUrlInfo()
  },
  methods: {
    // _getUrlInfo () {
    //   try {
    //     const server = window.location.origin.split('//')[1]
    //     getUrlInfo({ domain: server }).then(res => {
    //       this.homeRecord = res.data.home_record
    //       this.icp = res.data.icp
    //     }).catch(() => {
    //     })
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
    clickTop (i) {
      this.tabIndex = i
      switch (this.tabIndex) {
        case 0:
          this.tuWenData = this.getZongHeData.map(item => {
            item.type = parseInt(Math.random() * 2 + 1, 10)
            item.shareNum = parseInt(Math.random() * 800 + 600, 10)
            item.time = parseInt(Math.random() * 60 + 1, 10)
            return item
          })
          break
        // case 1:
        //   this.tuWenData = this.getJunShiData.map(item => {
        //     item.type = parseInt(Math.random() * 2 + 1, 10)
        //     item.shareNum = parseInt(Math.random() * 800 + 600, 10)
        //     item.time = parseInt(Math.random() * 60 + 1, 10)
        //     return item
        //   })
        //   break
        case 1:
          this.tuWenData = this.getYuLeData.map(item => {
            item.type = parseInt(Math.random() * 2 + 1, 10)
            item.shareNum = parseInt(Math.random() * 800 + 600, 10)
            item.time = parseInt(Math.random() * 60 + 1, 10)
            return item
          })
          break
        case 2:
          this.tuWenData = this.getTiYuData.map(item => {
            item.type = parseInt(Math.random() * 2 + 1, 10)
            item.shareNum = parseInt(Math.random() * 800 + 600, 10)
            item.time = parseInt(Math.random() * 60 + 1, 10)
            return item
          })
          break
        case 4:
          this.tuWenData = this.getGuoJiData.map(item => {
            item.type = parseInt(Math.random() * 2 + 1, 10)
            item.shareNum = parseInt(Math.random() * 800 + 600, 10)
            item.time = parseInt(Math.random() * 60 + 1, 10)
            return item
          })
          break
        case 5:
          this.tuWenData = this.getGuoNeiData.map(item => {
            item.type = parseInt(Math.random() * 2 + 1, 10)
            item.shareNum = parseInt(Math.random() * 800 + 600, 10)
            item.time = parseInt(Math.random() * 60 + 1, 10)
            return item
          })
          break
        default:
          break
      }
    },
    clickTuwenCell (row) {
      this.$router.push({
        path: '/tuwen-detail',
        query: { content: row.zwWithLabel, title: row.title, shareNum: row.shareNum }
      })
    }
  }
}
</script>

<style scoped>
.page{
  padding-bottom: 250px;
  position: relative;
}
.top-tab {
  width: 100%;
  display: flex;
  position: fixed;
  height: 80px;
  top: 0;
  align-items: center;
  justify-content: space-around;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 31px;
  z-index: 10000;
  background: #fff;
  border-bottom: 2px solid  #EDEDED;
}
.top-tab-cell {
  position: relative;
  /* margin-left: 20px; */
}
/* .top-tab-cell-new-new {
  position: absolute;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  text-align: center;
  line-height: 24px;
  width: 28px;
  height: 24px;
  background: linear-gradient(90deg, #E76B61 0%, #FF956A 100%);
  border-radius: 12px 12px 12px 2px;
  top: -20px;
  right: -30px;
} */
.top-tab-cell.active {
  color: #D4554B;
}
/* .top-tab-cell.active:before {
  content: '';
  width: 60%;
  height: 6px;
  position: absolute;
  background: #D4554B;
  bottom: -100%;
  left: 20%;
} */
.page-tuwen {
  position: relative;
  top: 100px;
  padding: 0 20px;
  height: 100%;
  margin-top: 10px;
  display: flex;
}
.page-tuwen-cell {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 200px;
  border-bottom: 2px solid #EDEDED;
}
.page-tuwen-cell-left {
  display: flex;
  flex-direction: column;
  /* margin-left: 20px; */
}
.page-tuwen-cell-title {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: 420px;
  font-size: 32px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 50px;
}
.page-tuwen-cell-other {
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.page-tuwen-cell-share {
  padding: 5px;
  background: #FCECEB;
  border-radius: 5px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #F44B50;
  margin-right: 20px;
}

.page-tuwen-cell-time {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}
.page-tuwen-cell-right {
  width: 231px;
  height: 172px;
  border-radius: 5px;
}
.page-tuwen-cell-right img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.label {
  width: 45px;
  height: 25px;
  display: inline-block;
  background: #F43728;
  border-radius: 4px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #fff;
  line-height: 25px;
  text-align: center;
}

.page-tuwen-cell-type {
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #EDEDED;
}

.page-tuwen-cell-type-title {
  font-size: 32px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 50px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.page-tuwen-cell-type-imgs {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page-tuwen-cell-type-imgs img {
  width: 220px;
  height: 145px;
  border-radius: 5px;
}
.page-tuwen-cell-type-other {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.page-tuwen-cell-type-share {
  padding: 5px;
  background: #FCECEB;
  border-radius: 5px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #F44B50;
  margin-right: 20px;
}

.page-tuwen-cell-type-time {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}

.web-info {
  width: 100%;
  height: 100px;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>
